import React, { useState, useEffect } from 'react'
import { NotFoundHeader } from 'components/Blocks'

const NotFoundPage = ({ location }) => {
    const [name, setName] = useState('')
    useEffect(() => {
        if (location.state) {
            setName(location.state.name)
        }
    }, [])
    const meta = {
        title: '404 - Not found | The Bodyguard Musical',
    }
    return <NotFoundHeader name={name} />
}

export default NotFoundPage
